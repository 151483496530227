import cn from 'classnames';
import { FC, ReactNode } from 'react';

interface IProps {
    label?: ReactNode;
    Icon?: any;
    children: ReactNode;
    intent?: 'success' | 'warning' | 'danger';
    alignSelf?: 'start' | 'end' | 'center';
    customClassName?: string;
}

const InfoItem: FC<IProps> = ({ label, Icon, children, intent, alignSelf, customClassName }) => {
    return (
        <>
            <dt
                className={cn(
                    'v2-info__title',
                    {
                        [`v2-info__title--${intent}`]: intent,
                    },
                    customClassName,
                )}
                style={{ alignSelf: alignSelf || 'center' }}
            >
                <span>
                    {Icon ? (
                        <span className="job-summary__hcp-double-up">
                            <Icon
                                className="job-summary__double-up-icon"
                                style={{ verticalAlign: 'middle' }}
                            />
                        </span>
                    ) : (
                        label
                    )}
                </span>
            </dt>
            <dd
                className={cn(
                    'v2-info__definition',
                    {
                        [`v2-info__definition--${intent}`]: intent,
                    },
                    customClassName,
                )}
            >
                {children || <span className="v2-info__not-set">Not set</span>}
            </dd>
        </>
    );
};

export default InfoItem;
